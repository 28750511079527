// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-escuelas-vacaciones-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\escuelas-vacaciones.js" /* webpackChunkName: "component---src-pages-escuelas-vacaciones-js" */),
  "component---src-pages-excursiones-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\excursiones.js" /* webpackChunkName: "component---src-pages-excursiones-js" */),
  "component---src-pages-extraescolares-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\extraescolares.js" /* webpackChunkName: "component---src-pages-extraescolares-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-trabaja-con-nosotros-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\trabaja-con-nosotros.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-js" */),
  "component---src-pages-verano-2019-js": () => import("C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\src\\pages\\verano2019.js" /* webpackChunkName: "component---src-pages-verano-2019-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\daniel\\proyectos\\grupoaristas\\web2019\\.cache\\data.json")

